<template>
    <div>
        <page-header pageTitle="DRIVE_FILES_TYPE.INDEX" :btnNew="'ACTIONS.NEW'"
                     @newType="setAddModal"/>
        <div v-if="this.items?.data && !this.items.data.validations" class="card mw-100">
            <div class="row mw-100">
                <div class="col-4">
                    <div class="font-weight-bolder mt-2 ml-4 text-left">
                        {{ this.t('DRIVE_FILES_TYPE.NAME') }}
                    </div>
                </div>
                <div class="col-3">
                    <div class="font-weight-bolder mt-2 text-left" style="margin-left: 1.5rem">
                        {{ this.t('DRIVE_FILES_TYPE.REQUIRE_ON_PERSON') }}
                    </div>
                </div>
                <div class="col-4">
                    <div class="font-weight-bolder mt-2 text-left" style="margin-left: 1.5rem">
                        {{ this.t('DRIVE_FILES_TYPE.REQUIRE_ON_PERSON_JURIDICAL') }}
                    </div>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 font-bold"
                     style="font-size: 1rem;">
                </div>
            </div>
            <div v-for="(iten, key) in items.data" :key="iten.id" :class="key !=0 ? 'border-top2': ''">
                <div class="row mw-100 space-between mt-2">
                    <div class="col-4">
                        <div class="list-primary hide-text text-left">
                            <div class="w-100">
                                {{ iten.name }}
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="list-primary hide-text text-left">
                            <div class="w-100">
                                {{ iten.required_on_person ? 'Sim' : 'Não' }}
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="list-primary hide-text text-left">
                            <div class="w-100">
                                {{ iten.required_on_person_juridical ? 'Sim' : 'Não' }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" style="margin-left: -0.1rem">
                        <div class="text-right">
                            <a type="button" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false" class="w-100"
                               style="font-size: 20px" id="listDropdown">
                                <i class="icon-more_vert" title="Opções"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right dropdown-black w-auto">
                                <div class="dropdown-item pointer text-white"
                                     @click="setVieModal(iten.id)"
                                     data-toggle="modal" data-target="#customModalTwo">
                                    <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                                </div>
                                <div class="dropdown-item pointer text-white"
                                     data-toggle="modal" data-target="#customModalTwo" @click="setEditModal(iten.id)">
                                    <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                                </div>
                                <div class="dropdown-item pointer text-white"
                                     @click="deleteIten(iten)">
                                    <i class="icon-trash mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="!items?.data?.length">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('DRIVE_FILES_TYPE.NONE_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <modal :modal-large="true" :title-modal="modalTitle" @close="closeModal()">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <create-drive-file-type-component v-if="modalSwitch === 1" @created="index()"
                                              :is-view="modalView" :is-edit="modalEdit" :item-index="item"
                                              @close="closeModal()"></create-drive-file-type-component>
        </modal>
        <pagination-component v-if="items && items?.data" :items="items" :to="'/tipo-propriedade'"
                              @changePage="index($event)"></pagination-component>
    </div>
</template>


<script>
import pageHeader from '../../components/layouts/pageHeader';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import DriveFilesType from '../../services/DriveFilesType';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import modal from '../../components/modal';
import createDriveFileTypeComponent from './createDriveFileTypeComponent';
import errorMsg from '../../components/errorMsg';

export default {
    name: "indexDriveFileTypeComponent",
    components: {
        pageHeader,
        PaginationComponent,
        modal,
        createDriveFileTypeComponent,
    },
    mixins:[errorMsg],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            items: {},
            item: {},
            modalView: false,
            modalEdit: false,
            modalSwitch: false,
            modalTitle: '',
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index(page = 1) {
            this.$store.commit('changeLoading', true);
            DriveFilesType.index(page, {}).then((resp) => {
                this.items = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.items = {};
                this.$store.commit('changeLoading', false);
            })
        },
        deleteIten(iten) {
            this.$store.commit('changeLoading', true);
            DriveFilesType.delete(iten.id).then(() => {
                this.toast.success(this.t('GENERAL.MSG.DELETED_SUCCESS'))
                this.index();
            }).catch((err) => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            })
        },
        closeModal() {
            document.getElementById('closeX')?.click();
            this.modalView = false;
            this.modalEdit = false;
            this.item = {};
            this.modalSwitch = null;
        },
        setVieModal(id){
            this.$store.commit('changeLoading', true);
            this.modalView = true;
            this.modalEdit = false;
            this.modalTitle = 'DRIVE_FILES_TYPE.VIEW';
            this.modalSwitch = 1;
            DriveFilesType.show(id).then((resp) => {
                this.item = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        setEditModal(id){
            this.$store.commit('changeLoading', true);
            this.modalView = false;
            this.modalEdit = true;
            this.modalTitle = 'DRIVE_FILES_TYPE.VIEW';
            this.modalSwitch = 1;
            DriveFilesType.show(id).then((resp) => {
                this.item = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            });
        },
        setAddModal() {
            this.modalView = false;
            this.modalEdit = false;
            this.item = {};
            this.modalTitle = 'DRIVE_FILES_TYPE.ADD_NEW';
            this.modalSwitch = 1;
        }
    }
}
</script>

<style scoped>

</style>