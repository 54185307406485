<template>
    <div>
        <div class="modal-row2 modal-height-max">
            <div class="modal-body">
                <div class="row gutters mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="name" class="required">Nome: </label>
                            <input type="text" id="name" v-model="iten.name"
                                   placeholder="Nome"  :disabled="isView"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="name">Vinculado a: </label>
                            <select2 v-model="iten.vinculed_at" :settings="{width:'100%'}" :options="[
                                    {id:'selecione', text: 'Nenhum'},
                                     {id:'fisica', text: 'Pessoa Fisica'},
                                    {id:'juridica', text: 'Pessoa Jurídica'},
                            ]" :disabled="isView" placeholder="Selecione" @select="selectVinculed"></select2>
                        </div>
                    </div>
                    <div class="col-12" v-if="!iten.vinculed_at || iten.vinculed_at === 'fisica'">
                        <div class="custom-control custom-switch">
                            <input data-cy="user_index_active_checkbox" type="checkbox"
                                   v-model="iten.required_on_person"
                                   :disabled="isView"
                                   class="custom-control-input" id="required_person">
                            <label class="custom-control-label" for="required_person">{{
                                    t('DRIVE_FILES_TYPE.REQUIRED_PERSON')
                                }}</label>
                        </div>
                    </div>
                    <div class="col-12" v-if="!iten.vinculed_at || iten.vinculed_at === 'juridica'">
                        <div class="custom-control custom-switch">
                            <input data-cy="user_index_active_checkbox" type="checkbox"
                                   v-model="iten.required_on_person_juridical"
                                   :disabled="isView"
                                   class="custom-control-input" id="required_on_person_juridical">
                            <label class="custom-control-label" for="required_on_person_juridical">{{
                                    t('DRIVE_FILES_TYPE.REQUIRED_PERSON_JURIDICAL')
                                }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn btn-secondary" @click="$emit('close')">{{t('ACTIONS.CLOSE')}}</div>
                <div class="btn btn-primary" v-if="!isView" @click="save">{{t('ACTIONS.SAVE')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import DriveFilesType from '../../services/DriveFilesType';
import errorMsg from '../../components/errorMsg';
import Select2 from "vue3-select2-component";

export default {
    name: "createDriveFileTypeComponent",
    data(){
        return{
            iten:{},
        }
    },
    components:{
        Select2,
    },
    props:{
        itemIndex:{
            type: Object,
        },
        isView: {
            type: Boolean,
            default: false,
        },
        isEdit: {
            type: Boolean,
            default: false,
        }
    },
    watch:{
        itemIndex(value){
            this.iten = value
        }
    },
    mixins:[errorMsg],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    mounted() {
        this.iten = this.itemIndex;
    },
    methods:{
        selectVinculed({id}){
            if (id === 'selecione'){
                this.iten.vinculed_at = null;
            }
            if (id === 'fisica'){
                this.iten.required_on_person_juridical = false;
            }
            if (id === 'juridica'){
                this.iten.required_on_person = false;
            }
        },
        save(){
            if (!this.iten.name){
                this.toast.error('Campo Nome é Obrigatório');
                return;
            }
            this.iten.required_on_person = !!this.iten.required_on_person;
            this.iten.required_on_person_juridical = !!this.iten.required_on_person_juridical;
            if (this.iten.vinculed_at === 'juridica') this.iten.required_on_person = false;
            if (this.iten.vinculed_at === 'fisica') this.iten.required_on_person_juridical = false;
            this.$store.commit('changeLoading', true);
            if (this.isEdit){
                DriveFilesType.edit(this.iten).then(() => {
                    this.toast.success(this.t('GENERAL.UPDATED_SUCCESSFULL'));
                    document.getElementById('closeX')?.click();
                    this.$emit('created');
                }).catch((err) => {
                    this.errorMsg(err);
                    this.$store.commit('changeLoading', false);
                })
            }else {
                DriveFilesType.create(this.iten).then(() => {
                    this.toast.success(this.t('GENERAL.CREATED_SUCCESSFULL'));
                    document.getElementById('closeX')?.click();
                    this.$emit('created');
                }).catch((err) => {
                    this.errorMsg(err);
                    this.$store.commit('changeLoading', false);
                })
            }

        }
    }
}
</script>

<style scoped>

</style>